<template>
  <div class="modal">
    <div class="modal__description">
      <p class="description__title">
        {{ $t(`settings.dataNormalization.module.infoModal.descriptionTitle`) }}
      </p>
      <p class="description__text">
        {{
          $t(NORMALIZED_FIELD_DEFINITION[normalizationFieldEntry]!.definition)
        }}
      </p>
    </div>
    <div class="modal__details">
      <div class="details__values-container">
        <div
          class="values-container__total"
          :class="{
            'values-container__total--incomplete':
              normalizationAnalytics.normalizedValuesTotal !==
              normalizationAnalytics.valuesTotal
          }"
        >
          <i class="icon-normalization total__icon" />
          <p class="total__text">
            {{
              normalizationAnalytics.normalizedValuesTotal !==
              normalizationAnalytics.valuesTotal
                ? $t(
                    `settings.dataNormalization.module.infoModal.incompleteStat`,
                    {
                      count:
                        normalizationAnalytics.valuesTotal -
                        normalizationAnalytics.normalizedValuesTotal,
                      total: normalizationAnalytics.valuesTotal
                    }
                  )
                : $t(
                    `settings.dataNormalization.module.infoModal.completeStat`,
                    {
                      count: normalizationAnalytics.normalizedValuesTotal,
                      total: normalizationAnalytics.valuesTotal
                    }
                  )
            }}
          </p>
        </div>
        <div class="values-container__options">
          <p class="options__title">
            {{
              $t(`settings.dataNormalization.module.infoModal.normalizedValues`)
            }}
          </p>
          <div class="options__item">
            <div
              v-for="(option, index) in normalizedFieldOptions"
              :key="option"
              class="item"
            >
              <ReflectTag
                class="item__tag"
                :text="
                  $t(
                    `settings.dataNormalization.module.normalizationValues.${option}.title`,
                    getAllRenamings()
                  )
                "
                :theme="
                  Object.values(TagTheme)[
                    index % Object.values(TagTheme).length
                  ]
                "
              />
              <p class="item__description">
                {{
                  $t(
                    `settings.dataNormalization.module.normalizationValues.${option}.description`
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="details__impacts-container">
        <p
          v-if="impactedMembers.measures.length > 0"
          class="impacts-container__section-title"
        >
          {{
            $t(`settings.dataNormalization.module.infoModal.impactedMetrics`)
          }}
        </p>
        <div
          v-for="measure in impactedMembers.measures"
          :key="measure.name"
          class="impacts-container__member"
        >
          <i :class="`member__icon icon-${getMemberFormatIcon(measure)}`" />
          <p
            class="member__label"
            :title="getDefaultMeasureTranslation(measure)"
          >
            {{ getDefaultMeasureTranslation(measure) }}
          </p>
        </div>
        <p
          v-if="impactedMembers.dimensions.length > 0"
          class="impacts-container__section-title"
        >
          {{
            $t(`settings.dataNormalization.module.infoModal.impactedDimensions`)
          }}
        </p>
        <div
          v-for="dimension in impactedMembers.dimensions"
          :key="dimension.name"
          class="impacts-container__member"
        >
          <i :class="`member__icon icon-${getMemberFormatIcon(dimension)}`" />
          <p
            class="member__label"
            :title="getDimensionTranslation(dimension)"
          >
            {{ getDimensionTranslation(dimension) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { NORMALIZED_FIELD_DEFINITION } from '~/constants/sourceValues'
import {
  getAllRenamings,
  getDefaultMeasureTranslation,
  getDimensionFromTitle,
  getDimensionTranslation,
  getMeasureFromTitle,
  getMemberFormatIcon
} from '~/services/explore'
import { getUniqueImpactedMembers } from '~/services/sourceValue'
import {
  NormalizedFieldEntry,
  type NormalizationAnalytics
} from '~/types/sourceValue'
import { TagTheme } from '~/types/tagTheme'

export default {
  name: 'NormalizationFieldInfoContainer',
  props: {
    normalizationFieldEntry: {
      type: String as PropType<NormalizedFieldEntry>,
      required: true
    },
    normalizationAnalytics: {
      type: Object as PropType<NormalizationAnalytics>,
      required: true
    },
    normalizedFieldOptions: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup() {
    return {
      NORMALIZED_FIELD_DEFINITION,
      TagTheme,
      getMemberFormatIcon,
      getDefaultMeasureTranslation,
      getDimensionTranslation,
      getAllRenamings
    }
  },
  computed: {
    impactedMembers() {
      const membersShortTitle = getUniqueImpactedMembers(
        this.normalizationFieldEntry
      )
      return {
        measures: membersShortTitle.measures.map(m => getMeasureFromTitle(m)),
        dimensions: membersShortTitle.dimensions.map(d =>
          getDimensionFromTitle(d)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  height: 520px;
  width: 920px;
  @include font-text($font-weight-book);
  @include font-size($font-size-regular);
  display: flex;
  flex-direction: column;

  &__description {
    border-radius: 8px;
    border: 1px solid $border-quaternary;
    background-color: $bg-quaternary;

    .description {
      &__title {
        @include font-text($font-weight-medium);
        margin: $margin-small $margin-regular;
      }

      &__text {
        margin: $margin-small $margin-regular;
      }
    }
  }

  &__details {
    margin-top: $margin-small;
    display: flex;
    flex: 1;
    overflow: hidden;

    .details {
      &__values-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .values-container {
          &__total {
            display: flex;
            align-items: center;
            background-color: $tag-senary;
            border-radius: 8px;

            &--incomplete {
              background-color: $tag-septenary;
            }

            .total {
              &__icon {
                @include font-icon;
                margin-left: $margin-regular;
              }

              &__text {
                margin: $margin-small $margin-tiny;
              }
            }
          }

          &__options {
            border-radius: 8px;
            border: 1px solid $border-quaternary;
            background-color: $bg-quaternary;
            margin-top: $margin-small;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .options {
              display: flex;
              flex-direction: column;
              margin: $margin-mini $margin-regular;

              &__title {
                margin: $margin-regular;
                @include font-text($font-weight-medium);
              }

              &__item {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                margin: 0 $margin-regular;

                .item {
                  display: flex;
                  margin-top: $margin-mini;

                  &__tag {
                    align-self: baseline;
                    white-space: nowrap;
                  }

                  &__description {
                    display: block;
                    white-space: pre-line;
                    margin-left: $margin-mini;
                  }
                }
              }
            }
          }
        }
      }

      &__impacts-container {
        width: 340px;
        border-radius: 8px;
        border: 1px solid $border-quaternary;
        background-color: $bg-quaternary;
        margin-left: $margin-small;
        overflow: auto;

        .impacts-container {
          &__section-title {
            @include font-text($font-weight-medium);
            margin: $margin-small $margin-regular;
          }

          &__member {
            margin: $margin-tiny $margin-regular;
            display: flex;
            align-items: center;

            .member {
              &__icon {
                @include font-icon;
                @include font-size(20px);
                align-self: flex-end;
              }

              &__label {
                margin-left: $margin-tiny;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }

          &__member:last-child {
            margin-bottom: $margin-regular;
          }
        }
      }
    }
  }
}
</style>
